import LogInModal from "./LogInModal";
import SignUpModal from "./SignUpModal";

export const Navigation = (props) => {
  return (
    <nav id='menu' className='navbar navbar-default navbar-fixed-top'>
      <div className='container'>
        <div className='navbar-header'>
          <a className='navbar-brand page-scroll' href='#page-top'>
            SharpMenu
          </a>{' '}
        </div>
        <div className="pull-right">
          <LogInModal />
          <SignUpModal
            prePopulatedEmail={""}
            buttonText={"Sign Up"}
          />
        </div>
      </div>
    </nav>
  )
}
