var city_info = [
	{
		text : "/tx/houston",
		value: "Houston, TX"
	},
	{
		text : "/tx/dallas",
		value: "Dallas, TX"
	},
	{
		text : "/ca/sunnyvale",
		value: "Sunnyvale, CA"
	},
	{
		text : "/ny/new-york",
		value: "New York, NY"
	},
	{
		text : "/ca/los-angeles",
		value: "Los Angeles, CA"
	},
	{
		text : "/ca/san-francisco",
		value: "San Francisco, CA"
	},
	{
		text : "/ma/boston",
		value: "Boston, MA"
	},
	{
		text : "/il/chicago",
		value: "Chicago, IL"
	},
	{
		text : "/ga/atlanta",
		value: "Atlanta, GA"
	},
	{
		text : "/dc/washington",
		value: "Washington, DC"
	},
	{
		text : "/nj/edison",
		value: "Edison, NJ"
	},
	{
		text : "/nj/jersey-city",
		value: "Jersey City, NJ"
	},
	{
		text : "/wa/redmond",
		value: "Redmond, WA"
	},
	{
		text : "/wa/seattle",
		value: "Seattle, WA"
	},
	{
		text : "/wa/kirkland",
		value: "Kirkland, WA"
	},
	{
		text : "/tx/austin",
		value: "Austin, TX"
	},
	{
		text : "/wa/kent",
		value: "Kent, WA"
	},
	{
		text : "/ca/fremont",
		value: "Fremont, CA"
	},
	{
		text : "/ca/oakland",
		value: "Oakland, CA"
	},
	{
		text : "/ca/south-san-francisco",
		value: "South San Francisco, CA"
	},
	{
		text : "/ca/mountain-view",
		value: "Mountain View, CA"
	},
	{
		text : "/ca/palo-alto",
		value: "Palo Alto, CA"
	},
	{
		text : "/ca/menlo-park",
		value: "Menlo Park, CA"
	},
	{
		text : "/ca/redwood-city",
		value: "Redwood City, CA"
	},
	{
		text : "/ca/san-jose",
		value: "San Jose, CA"
	},
	{
		text : "/ca/cupertino",
		value: "Cupertino, CA"
	},
	{
		text : "/ny/bronx",
		value: "Bronx, NY"
	},
	{
		text : "/ny/brooklyn",
		value: "Brooklyn, NY"
	},
	{
		text : "/ny/queens",
		value: "Queens, NY"
	},
	{
		text : "/az/phoenix",
		value: "Phoenix, AZ"
	},
	{
		text : "/fl/miami",
		value: "Miami, FL"
	},
	{
		text : "/fl/orlando",
		value: "Orlando, FL"
	},
	{
		text : "/ca/san-diego",
		value: "San Diego, CA"
	},
	{
		text : "/tn/knoxville",
		value: "Knoxville, TN"
	},
	{
		text : "/pa/philadelphia",
		value: "Philadelphia, PA"
	},
	{
		text : "/tx/san-antonio",
		value: "San Antonio, TX"
	},
	{
		text : "/fl/jacksonville",
		value: "Jacksonville, FL"
	},
	{
		text : "/nc/charlotte",
		value: "Charlotte, NC"
	},
	{
		text : "/nc/chapel-hill",
		value: "Chapel Hill, NC"
	}
];


export {city_info};
