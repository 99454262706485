import { useState, Component } from "react";

import {
  Card,
  Col,
  Row,
  Typography,
  Tooltip,
  Progress,
  Upload,
  message,
  Button,
  Timeline,
  Radio,
  Avatar,
  Table,
  Input,
  AutoComplete,
  List,
  Spin
} from "antd";
import {
  ToTopOutlined,
  MenuUnfoldOutlined,
  RightOutlined,
  LoadingOutlined
} from "@ant-design/icons";
import Paragraph from "antd/lib/typography/Paragraph";
import { Link } from "react-router-dom";
import axios from 'axios';
import {menu_item_names} from '../assets/menu_metadata.js';
import {city_info} from '../assets/city_info.js';
import ReactGA from "react-ga";

const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 24,
    }}
    spin
  />
);
const { Title, Text } = Typography;
const { Search } = Input;

class Recommendations extends Component {
  constructor(props) {
        super(props);
        // project table start
        const search_results = [];
        const typeahead_suggestions = [];
        var suggestion_set = new Set();
        for (let x in menu_item_names) {
          var item_value = menu_item_names[x].trim();
          if (!suggestion_set.has(item_value.toLowerCase())) {
            typeahead_suggestions.push({value: item_value});
            suggestion_set.add(item_value.toLowerCase())
          }
        }
        console.log(typeahead_suggestions)
        const search_result_set = [];
        const additional_data = [];
        const fetching_results = false;
        this.state = {search_result_set, search_results, additional_data, typeahead_suggestions, fetching_results};
    }

  handleSelect = e => {
    console.log(e)
    var cleaned_input = e.replace(/[^a-zA-Z ]/g, "").trim()
    ReactGA.event({
      category: "RecsPage_Search",
      action: "dish_name",
      label: cleaned_input}
    );
    console.log(cleaned_input)
    this.setState({fetching_results: true});
    axios.get(`https://pwdfzgte2mr5ez5nno6fzfq2ki0ioxbm.lambda-url.us-west-1.on.aws/?query_text=` + encodeURIComponent(cleaned_input))
    .then(res => {
      this.setState({fetching_results: false});
      console.log(res.data)
      this.setState({search_results:res.data});
      this.setState({selected_dish_name:e});
    });
  }


  render() {
    const results = <Col flex="none">
      <Card
        bordered={false}
        className="criclebox mb-24"
        title={"People who liked '" + this.state.selected_dish_name + "' may also like"}
      >
        <div className="table-responsive">
          <List
            grid={{
              gutter: 16,
              xs: 1,
              sm: 2,
              md: 4,
              lg: 4,
              xl: 6,
              xxl: 3
            }}
            dataSource={this.state.search_results}
            renderItem={(item) => (
              <List.Item>
                <Card
                  className="card-project"
                  title={item.item_name}>
                  <p>similarity: {(item.score * 100).toFixed(0)}%</p>
                  {item.item_desc}
                </Card>
              </List.Item>
            )}
          />
        </div>
      </Card>
    </Col>
    let to_show_component;
    if (this.state.search_results.length != 0) {
      to_show_component = results;
    } else {
      to_show_component = <div></div>
    }
    return (
      <div className="layout-content">
        <Row className="rowgap-vbox">
          <Col
            className="mb-24"
            span={12} offset={4}
          >
            <AutoComplete
              dropdownClassName="certain-category-search-dropdown"
              options={this.state.typeahead_suggestions}
              filterOption={(inputValue, option) =>
                option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
              }
              style={{width: 600}}
              align="middle"
              showArrow="True"
              onSelect={this.handleSelect}
            >
            <Input size="large" placeholder="Select a dish from the dropdown"/>
            </AutoComplete>
            <Spin indicator={antIcon} spinning={this.state.fetching_results}/>
          </Col>
        </Row>
        <Row className="rowgap-vbox">
          {to_show_component}
        </Row>
      </div>
  );
}
}

export default Recommendations;
