import {LogoutOutlined } from '@ant-design/icons';
import { Auth } from 'aws-amplify';
import {
  Button,
} from "antd";
import { useLocation, useHistory, withRouter, Redirect } from "react-router-dom";
import { useState, Component, setState } from "react";
import ReactGA from "react-ga";

export const SignOutButton = (props) => {
  const history = useHistory()
  async function signOut() {
    try {
        await Auth.signOut();
        ReactGA.event({
          category: "SignOutPage",
          action: "click",
          label: "1"}
        );
        history.push("/")
    } catch (error) {
        console.log('error signing out: ', error);
    }
  }

  return (
    <Button type="primary" icon={<LogoutOutlined />} onClick={signOut}>
      Sign Out
    </Button>
  );
}

export default SignOutButton;
