import { FileUploader } from "react-drag-drop-files";
import { useState, Component } from "react";
import {
  Card,
  Col,
  Row,
  Typography,
  Tooltip,
  Progress,
  Upload,
  message,
  Button,
  Timeline,
  Radio,
  Avatar,
  Table,
  Input
} from "antd";
import {
  ToTopOutlined,
  MenuUnfoldOutlined,
  RightOutlined,
  InboxOutlined
} from "@ant-design/icons";
import Paragraph from "antd/lib/typography/Paragraph";
import { Link } from "react-router-dom";
import axios from 'axios';
import SampleMenuFormat from './data/sample_menu.xlsx';
import { Amplify, Storage } from 'aws-amplify';
import { Tabs } from 'antd';
import ReactGA from "react-ga";

const { TabPane } = Tabs;
const { Title, Text } = Typography;
const { Search } = Input;
const { Dragger } = Upload;
class Insights extends Component {
  constructor(props) {
        super(props);
        // dish_table_headers table start
        const dish_table_headers = [
          {
            title: "DISH",
            dataIndex: "dish_name",
          },
          {
            title: "NUMBER OF MAIN INGREDIENTS RE-USED",
            dataIndex: "first_5_reused",
            sorter: {
              compare: (a, b) => a.first_5_reused - b.first_5_reused,
            },
          },
          {
            title: "NUMBER OF INGREDIENTS RE-USED",
            dataIndex: "all_reused",
            sorter: {
              compare: (a, b) => a.all_reused - b.all_reused,
            },
          },
          {
            title: "NUMBER OF INGREDIENTS",
            dataIndex: "num_ingreds",
            sorter: {
              compare: (a, b) => a.num_ingreds - b.num_ingreds,
            },
          }
        ];
        const ingred_table_headers = [
          {
            title: "INGREDIENT",
            dataIndex: "ingred_name",
          },
          {
            title: "NUMBER OF DISHES",
            dataIndex: "num_dishes",
            sorter: {
              compare: (a, b) => a.num_dishes - b.num_dishes,
            },
          },
          {
            title: "DISH NAMES",
            dataIndex: "dish_names",
            sorter: {
              compare: (a, b) => a.dish_names - b.dish_names,
            },
          }
        ];
        const dish_result_set = [];
        const ingred_result_set = [];
        const additional_data = [];
        const fetching_results = false;
        this.state = {dish_result_set, ingred_result_set, additional_data, dish_table_headers, ingred_table_headers, fetching_results};
    }

  handleUploadAndMetricsCalc = async ({ file, onSuccess }) => {
    console.log("handleUploadAndMetricsCalc")
    ReactGA.event({
      category: "InsightsPage_UploadFile",
      action: "file_name",
      label: file.name}
    );
    const result = await Storage.put(file.name, file, {
      contentType: "application/vnd.ms-excel",
    });
    onSuccess("ok");
    console.log("storage s3 amplify");
    console.log(result);
    this.setState({fetching_results: true});
    await this.computeMetrics(file.name);
    this.setState({fetching_results: false});
  };

  computeMetrics = async (file_name) => {
    ReactGA.event({
      category: "InsightsPage_ComputeMetrics",
      action: "click",
      label: "1"}
    );
    console.log(file_name)
    axios.get(`https://hgtpt5ieilidajiiulg55ytrw40uxifu.lambda-url.us-west-1.on.aws/?file=` + file_name)
    .then(res => {
      console.log(res)
      var data = res.data
      var agg_metrics = data.aggregate_metrics
      var addit_data = []
      addit_data.push({title: "number of dishes", value: agg_metrics["num_dishes"]})
      addit_data.push({title: "number of ingredients", value: agg_metrics["num_ingreds"]})
      addit_data.push({title: "number of ingredients used atleast twice", value: agg_metrics["num_ingreds_reused"]});
      addit_data.push({title: "dishes with no reused ingredients", value: agg_metrics["num_dishes_with_no_reuse"]});
      this.setState({dish_result_set: res.data.dish_metrics });
      this.setState({ingred_result_set: res.data.ingred_metrics });
      this.setState({additional_data: addit_data});

      console.log(res.data)
    });
  }


  render() {
    return (
      <div className="layout-content">
        <Row className="rowgap-vbox">
          <Col
            className="mb-24"
            span={12} offset={4}
          >
            <Typography.Paragraph
              strong={true}
            >
              get insights about your dishes and ingredients by uploading your menu for analysis.
            </Typography.Paragraph>
            <Dragger
              multiple={false}
              fileList={this.state.selectedFileList}
              customRequest={this.handleUploadAndMetricsCalc}
            >
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">upload your menu to begin</p>
            </Dragger>
            <br/>
            <a
              href={SampleMenuFormat}
              download="Sample_Menu_Format.xlsx"
            >
              sample menu format (.xlsx)
            </a>
          </Col>
        </Row>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>

        <Row justify="space-between">
          {this.state.additional_data.map((c, index) => (
            <Col
              key={index}
              className="mb-24"
            >
              <Card bordered={false} className="criclebox ">
                <div className="number">
                  <Row gutter={[3, 0]}>
                    <Col>
                      <span>{c.title}</span>
                      <Title level={3}>
                        {c.value}
                      </Title>
                    </Col>
                  </Row>
                </div>
              </Card>
            </Col>
          ))}
        </Row>
        <Tabs defaultActiveKey="1">
            <TabPane tab="Dish Analysis" key="1">
              <Row className="rowgap-vbox">
                <Col span={24} >
                  <Card
                    bordered={false}
                    className="criclebox tablespace mb-24"
                    title="Dish Analysis"
                  >
                    <div className="table-responsive">
                      <Table
                        columns={this.state.dish_table_headers}
                        dataSource={this.state.dish_result_set}
                        pagination={false}
                        className="ant-border-space"
                        loading={this.state.fetching_results}
                      />
                    </div>
                  </Card>
                </Col>
              </Row>
            </TabPane>
            <TabPane tab="Ingredient Analysis" key="2">
              <Row className="rowgap-vbox">
                <Col span={24} >
                  <Card
                    bordered={false}
                    className="criclebox tablespace mb-24"
                    title="Ingredient Analysis"
                  >
                    <div className="table-responsive">
                      <Table
                        columns={this.state.ingred_table_headers}
                        dataSource={this.state.ingred_result_set}
                        pagination={false}
                        className="ant-border-space"
                        loading={this.state.fetching_results}
                      />
                    </div>
                  </Card>
                </Col>
              </Row>
            </TabPane>
          </Tabs>
      </div>
  );
}
}

export default Insights;
