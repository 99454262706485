import { useState } from 'react'
import { Button } from 'antd';
import { TermsAndConditions } from "./terms_and_conditions";
import { PrivacyNotice } from "./privacy_notice";

export const Contact = (props) => {
  return (
    <div>
      <div id='footer'>
        <div className='container text-center'>
            <div className="pull-left">
              info@sharpmenu.co
            </div>
            <div className="pull-right">
              <TermsAndConditions/>
              <PrivacyNotice/>
            </div>
            <span> &copy; 2022 SharpMenu </span>
        </div>
      </div>
    </div>
  )
}
