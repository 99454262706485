import React, {useEffect } from 'react';
import { Switch, Route, Redirect } from "react-router-dom";
import Pricing from "./pages/Pricing";
import LandingPage from "./pages/LandingPage";
import Insights from "./pages/Insights";
import Recommendations from "./pages/Recommendations";
import Main from "./components/layout/Main";
import "antd/dist/antd.css";
import "./assets/styles/main.css";
import "./assets/styles/responsive.css";
import { Amplify, Auth } from 'aws-amplify';
import awsconfig from './aws-exports';
import ReactGA from 'react-ga';
const TRACKING_ID = "UA-234367683-1"; // OUR_TRACKING_ID
Amplify.configure(awsconfig);
ReactGA.initialize(TRACKING_ID);

const App = () => {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <div className="App">
      <Switch>
        <Route exact path="/" exact component={LandingPage} />
        <Main>
          <Route exact path="/pricing" component={Pricing} />
          <Route exact path="/recommendations" component={Recommendations} />
          <Route exact path="/insights" component={Insights} />
        </Main>
      </Switch>
    </div>
  );
}

export default App;
