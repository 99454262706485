import { useState, Component } from "react";

import {
  Card,
  Col,
  Row,
  Typography,
  Tooltip,
  Progress,
  Upload,
  message,
  Button,
  Timeline,
  Radio,
  Avatar,
  Table,
  Input,
  Spin,
  AutoComplete,
  Select,
  InputNumber,
  Space
} from "antd";
import {
  ToTopOutlined,
  MenuUnfoldOutlined,
  RightOutlined,
  LoadingOutlined,
  SearchOutlined
} from "@ant-design/icons";
import Paragraph from "antd/lib/typography/Paragraph";
import { Link } from "react-router-dom";
import axios from 'axios';
import {city_info} from '../assets/city_info.js';
import ReactGA from "react-ga";

const { Option } = Select;

const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 24,
    }}
    spin
  />
);
const { Title, Text } = Typography;
const { Search } = Input;

class Pricing extends Component {
  constructor(props) {
        super(props);
        // project table start
        const project = [
          {
            title: "ITEM",
            dataIndex: "item_name",
          },
          {
            title: "PRICE",
            dataIndex: "item_price",
            sorter: {
              compare: (a, b) => Number(a.item_price.replace(/[^0-9.-]+/g,"")) - Number(b.item_price.replace(/[^0-9.-]+/g,"")),
            },
          },
          {
            title: "RESTAURANT",
            dataIndex: "rest_name",
          },
          {
            title: "DESCRIPTION",
            dataIndex: "item_desc",
          },
        ];
        const search_result_set = [];
        const additional_data = [];
        const fetching_results = false;
        const searchDisabled = true;
        const selectedCity = "";
        const queryText = "";
        this.state = {search_result_set, project, additional_data, fetching_results, searchDisabled, selectedCity};
    }

  handleCitySelect = e => {
    this.setState({searchDisabled: false})
    this.setState({selectedCity: e})
    console.log("selected_city: " + this.state.selectedCity)
    console.log(city_info)
    //var short_city_name = city_info.find(item => item.value === e);
    //console.log(short_city_name)
  }

  handleSearch = e => {
    this.setState({fetching_results: true});
    console.log(this.state.queryText)
    ReactGA.event({
      category: "PricingPage_HandleSearch",
      action: "query",
      label: this.state.queryText}
    );
    var cleaned_input_query = this.state.queryText.replace(/[^a-zA-Z ]/g, "").trim()
    console.log(this.state.selectedCity)
    //console.log(this.getCityShort(this.state.selectedCity))
    //var short_city_name = this.state.selectedCity.find(({value}) => value === this.state.selectedCity);
    //console.log(short_city_name)
    var cleaned_input_city = this.state.selectedCity.replace(/[^a-zA-Z ]/g, "").trim()
    axios.get(`https://au3tosirzk4qsr4znc7mkp224u0wmnuq.lambda-url.us-west-1.on.aws/?query_text=` + encodeURIComponent(cleaned_input_query) + "&city_name=" + encodeURIComponent(cleaned_input_city))
    .then(res => {
      var addit_data = []
      addit_data.push({title: "Dishes", value: res.data["num_unique_dish"]})
      addit_data.push({title: "Restaurants", value: res.data["num_unique_rest"]})
      addit_data.push({title: "Most Common Price", value: res.data["median_price"].toLocaleString('en-US', {style: 'currency', currency: 'USD',})});
      addit_data.push({title: "Our Suggested Price", value: res.data["median_price"].toLocaleString('en-US', {style: 'currency', currency: 'USD',})});
      addit_data.push({title: "Lowest Price", value: res.data["min_price"].toLocaleString('en-US', {style: 'currency', currency: 'USD',})});
      addit_data.push({title: "Highest Price", value: res.data["max_price"].toLocaleString('en-US', {style: 'currency', currency: 'USD',})});
      this.setState({search_result_set: res.data['results'] });
      this.setState({additional_data: addit_data});

      console.log(res.data)
      this.setState({fetching_results: false});
    });
  }


  render() {
    return (
      <div className="layout-content">
        <Row className="rowgap-vbox"  type="flex">
          <Col
            className="mb-24"
            span={9}
            style={{width: "70%"}}
          >
              <Input
                placeholder="Search by dish, cuisine, or ingredient"
                enterButton="Search"
                size="medium"
                value={this.state.queryText}
                onChange={(e) => this.setState({queryText: e.target.value})}
              />
          </Col>
          <Col
            className="mb-24"
            span={7}
          >
            <AutoComplete
              dropdownClassName="certain-category-search-dropdown"
              options={city_info}
              filterOption={(inputValue, option) =>
                option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
              }
              style={{width: "50%"}}
              align="middle"
              showArrow="True"
              onSelect={this.handleCitySelect}
            >
            <Input size="large" placeholder="City"/>
            </AutoComplete>
            <Button type="primary" icon={< SearchOutlined/>} onClick={this.handleSearch} disabled={this.state.searchDisabled}>
              Search
            </Button>
          </Col>
        </Row>
        <Row justify="space-between">
          {this.state.additional_data.map((c, index) => (
            <Col
              key={index}
              className="mb-24"
            >
              <Card bordered={false} className="criclebox ">
                <div className="number">
                  <Row gutter={[3, 0]}>
                    <Col>
                      <span>{c.title}</span>
                      <Title level={3}>
                        {c.value}
                      </Title>
                    </Col>
                  </Row>
                </div>
              </Card>
            </Col>
          ))}
        </Row>

        <Row className="rowgap-vbox">
          <Col span={24} >
            <Card
              bordered={false}
              className="criclebox tablespace mb-24"
              title="Results"
            >
              <div className="table-responsive">
                <Table
                  columns={this.state.project}
                  dataSource={this.state.search_result_set}
                  pagination={false}
                  className="ant-border-space"
                  loading={this.state.fetching_results}
                />
              </div>
            </Card>
          </Col>
        </Row>
      </div>
  );
}
}

export default Pricing;
