import { useState } from 'react';
import { LockOutlined, MailOutlined } from '@ant-design/icons';
import { Button, Modal, Checkbox, Form, Input, Typography } from 'antd';
import { Auth } from 'aws-amplify';
import { useHistory } from "react-router-dom";
import ReactGA from "react-ga";

const LogInModal = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentState, setCurrentState] = useState("login");
  const [userEmail, setUserEmail] = useState("");
  const history = useHistory();
  const { Title, Paragraph, Text, Link } = Typography;

  async function showModal() {
    console.log("called showModal");
    if (await isLoggedIn()) {
      console.log("user is logged in")
      history.push("/pricing");
      return;
    }
    setIsModalVisible(true);
    ReactGA.event({
      category: "LandingPage_LoginModal",
      action: "click",
      label: "1"}
    );
  };

  const handleOk = () => {
    console.log("called handleOk")
    setIsModalVisible(false);
    setCurrentState("login")
  };

  const handleCancel = () => {
    console.log("called handleCancel")
    setIsModalVisible(false);
    setCurrentState("login")
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
    setCurrentState("login")
  };

  async function loginUser(values) {
    console.log("in loginUser");
    try {
        const user = await Auth.signIn(values['email'], values['password']);
        console.log(user)
        history.push("/pricing");
        ReactGA.event({
          category: "LandingPage_LoginUser",
          action: "click",
          label: "1"}
        );
    } catch (error) {
        alert("Incorrect email or password. Try again.");
        console.log('error signing in', error);
        ReactGA.event({
          category: "LandingPage_LoginUser",
          action: "click",
          label: "0"}
        );
    }
  }

  async function isLoggedIn() {
    console.log("in isLoggedIn");
    try {
      await Auth.currentAuthenticatedUser({bypassCache: true});
      return true;
    } catch (error) {
      return false;
    }
  }

  async function sendPasswordResetCode(values) {
    console.log("in sendPasswordResetCode");
    // Send confirmation code to user's email
    try {
      await Auth.forgotPassword(values["email"]);
      setCurrentState("createNewPassword");
      ReactGA.event({
        category: "LandingPage_SentResetCode",
        action: "click",
        label: "1"}
      );
    } catch (error) {
      alert("Error sending password reset code. Try again.");
      ReactGA.event({
        category: "LandingPage_SentResetCode",
        action: "click",
        label: "0"}
      );
      return false;
    }
  }

  async function updatePasswordAndLogin(values) {
    console.log("in updatePasswordAndLogin");
    // Send confirmation code to user's email
    try {
      // Collect confirmation code and new password, then
      await Auth.forgotPasswordSubmit(values["email"], values["confirmation_code"], values["password"]);
      const user = await Auth.signIn(values['email'], values['password']);
      ReactGA.event({
        category: "LandingPage_UpdatePassLogin",
        action: "click",
        label: "1"}
      );
      history.push("/pricing");
    } catch (error) {
      alert("Error updating password. Try again.");
      ReactGA.event({
        category: "LandingPage_UpdatePassLogin",
        action: "click",
        label: "0"}
      );
      return false;
    }
  }

  function triggerPasswordResetModal(){
    console.log("in triggerPasswordResetModal");
    setCurrentState("sendPasswordReset");
  };

  const login = <Form
    name="normal_login"
    className="login-form"
    initialValues={{
      remember: false,
    }}
    onFinish={loginUser}
    autoComplete="on"
  >
    <Form.Item
      name="email"
      rules={[
        {
          required: true,
          message: '',
        },
      ]}
    >
      <Input
        prefix={<MailOutlined className="site-form-item-icon" />}
        placeholder="Email"
      />
    </Form.Item>
    <Form.Item
      name="password"
      rules={[
        {
          required: true,
          message: '',
        },
      ]}
    >
      <Input.Password
        prefix={<LockOutlined className="site-form-item-icon" />}
        placeholder="Password"
      />
    </Form.Item>
    <Form.Item>
      <a className="login-form-forgot" onClick={triggerPasswordResetModal}>
        Forgot password
      </a>
      <div className="pull-right">
        <Button type="primary" htmlType="submit" className="login-form-button">
          Log in
        </Button>
      </div>
    </Form.Item>
  </Form>

  const sendPasswordResetModal =
  <Form
    name="reset_pass"
    className="login-form"
    initialValues={{
      remember: false,
    }}
    onFinish={sendPasswordResetCode}
    autoComplete="off"
  >
    <Form.Item
      name="email"
      rules={[
        {
          required: true,
          message: '',
        },
      ]}
    >
      <Input
        prefix={<MailOutlined className="site-form-item-icon" />}
        placeholder="Email"
        value={userEmail}
      />
    </Form.Item>
    <Form.Item>
      <div className="pull-right">
        <Button type="primary" htmlType="submit" className="login-form-button">
          Send Reset Code
        </Button>
      </div>
    </Form.Item>
  </Form>

const createNewPassword =
<Form
  name="new_password"
  initialValues={{
    remember: false,
  }}
  onFinish={updatePasswordAndLogin}
  onFinishFailed={onFinishFailed}
  autoComplete="off"
>
  <Text level={4}>
  Enter the 6-digit confirmation code sent to your email.
  </Text>
  <br />
  <br />
  <Form.Item
    name="email"
    rules={[
      {
        required: true,
        message: '',
      }
    ]}
  >
    <Input
      placeholder={"Email"}
      value={userEmail}
      style={{
        width: 'calc(70%)',
      }}
    />
  </Form.Item>
  <Form.Item
    name="confirmation_code"
    rules={[
      {
        required: true,
        message: '',
      }
    ]}
  >
    <Input
      style={{
        width: 'calc(70%)',
      }}
      placeholder="Reset Code"
    />
  </Form.Item>
  <Form.Item
    name="password"
    rules={[
      {
        required: true,
        message: '',
      },
      {
        validator(_, value) {
          if (value.length >= 8) {
            return Promise.resolve();
          }
          return Promise.reject('Passwords must contain atleast 8 characters.');
        },
      }
    ]}
  >
    <Input.Password
      prefix={<LockOutlined className="site-form-item-icon" />}
      placeholder="Password"
      style={{
        width: 'calc(70%)',
      }}
    />
  </Form.Item>
  <Form.Item>
    <div className="pull-right">
      <Button type="primary" htmlType="submit" className="login-form-button">
        Reset
      </Button>
    </div>
  </Form.Item>
</Form>

  const componentStates = {"login": login, "sendPasswordReset": sendPasswordResetModal, "createNewPassword": createNewPassword}
  return (
    <>
      <a className='btn btn-custom btn-lg page-scroll'  onClick={showModal}>
        Login
      </a>{' '}
      <Modal
        title="Login"
        visible={isModalVisible}
        onCancel={handleCancel}
        closable={true}
        footer={null}
      >
        {componentStates[currentState]}
      </Modal>
    </>
  );
};

export default LogInModal;
