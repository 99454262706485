import { useState } from 'react';
import { Button, Modal, Checkbox, Form, Input, Typography, Alert } from 'antd';
import { LockOutlined, UserOutlined, MailOutlined } from '@ant-design/icons';
import { Auth } from 'aws-amplify';
import { useHistory } from "react-router-dom";
import { TermsAndConditions } from "./terms_and_conditions";
import { PrivacyNotice } from "./privacy_notice";
import ReactGA from "react-ga";

const SignUpModal = (props) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [stepNum, setStepNum] = useState("signUpForm");
  const [userEmail, setUserEmail] = useState("");
  const [userPass, setUserPass] = useState("");
  const { Title, Paragraph, Text, Link } = Typography;
  const history = useHistory();

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setStepNum("signUpForm");
  };


  const onFinish = (values) => {
    console.log('Success:', values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };


  async function signUp(values) {
    try {
        const { user } = await Auth.signUp({
            username: values["email"],
            password: values["password"],
            attributes: {
                "custom:name": values["name"]
            }
        });
        console.log(user);
        ReactGA.event({
          category: "LandingPage_SignUpUser",
          action: "click",
          label: "1"}
        );
        setStepNum("confirmationCode")
        setUserEmail(values["email"])
        setUserPass(values["password"])
    } catch (error) {
        alert("Error Signing Up")
        ReactGA.event({
          category: "LandingPage_SignUpUser",
          action: "click",
          label: "0"}
        );
        console.log('error signing up:', error);
    }
  }

  function finishSignUpWithCode(){
    setStepNum("confirmationCode");
  }

  function triggerResendCodeModal(){
    setStepNum("resendCodeModal");
  }

  async function confirmSignUp(values) {
    try {
      console.log("confirmSignUp")
      console.log(values)
      console.log(values['email'])
      console.log(values["confirmation_code"])
      await Auth.confirmSignUp(values['email'], values["confirmation_code"]);
      ReactGA.event({
        category: "LandingPage_SignUpConfirm",
        action: "click",
        label: "1"}
      );
      if(userEmail != "" && userPass != ""){
        await Auth.signIn(userEmail, userPass);
        history.push("/pricing");
      } else {
        // password not set, came through resend code path
        setStepNum("logInModal");
      }
    } catch (error) {
        ReactGA.event({
          category: "LandingPage_SignUpConfirm",
          action: "click",
          label: "0"}
        );
        alert("Error - incorrect Confirmation Code. Try again.")
        console.log('error confirming sign up', error);
    }
  }

  async function resendCode(values) {
      try {
          await Auth.resendSignUp(values['email']);
          console.log('code resent successfully');
          setStepNum("confirmationCode");
          ReactGA.event({
            category: "LandingPage_SignUpResendCode",
            action: "click",
            label: "1"}
          );
      } catch (err) {
          console.log('error resending code: ', err);
          ReactGA.event({
            category: "LandingPage_SignUpResendCode",
            action: "click",
            label: "0"}
          );
      }
  }

  async function logIn(values) {
      try {
          const user = await Auth.signIn(values['email'], values['password']);
          console.log(user)
          ReactGA.event({
            category: "LandingPage_SignUpConfirmLogin",
            action: "click",
            label: "1"}
          );
          history.push("/pricing");
      } catch (error) {
          ReactGA.event({
            category: "LandingPage_SignUpConfirmLogin",
            action: "click",
            label: "0"}
          );
          console.log('error signing in', error);
      }
  }
  const logInModal =
  <>
  <Alert
      message="Verification succesful!"
      description="Enter your password to login."
      type="success"
      closable
    />
  <br/>
  <Form
    name="normal_login"
    className="login-form"
    initialValues={{
      remember: true,
    }}
    onFinish={logIn}
    autoComplete="on"
  >
    <Form.Item
      name="email"
      rules={[
        {
          required: true,
          message: '',
        },
      ]}
    >
      <Input
        prefix={<MailOutlined className="site-form-item-icon" />}
        placeholder="Email"
        value={userEmail}
      />
    </Form.Item>
    <Form.Item
      name="password"
      rules={[
        {
          required: true,
          message: '',
        },
      ]}
    >
      <Input.Password
        prefix={<LockOutlined className="site-form-item-icon" />}
        placeholder="Password"
      />
    </Form.Item>
    <Form.Item>
      <div className="pull-right">
        <Button type="primary" htmlType="submit" className="login-form-button">
          Log In
        </Button>
      </div>
    </Form.Item>
  </Form>
</>
  const resendCodeModal =
  <>
  <Form
    name="normal_login"
    className="login-form"
    initialValues={{
      remember: true,
    }}
    onFinish={resendCode}
    autoComplete="on"
  >
    <Form.Item
      name="email"
      rules={[
        {
          required: true,
          message: '',
        },
      ]}
    >
      <Input
        prefix={<MailOutlined className="site-form-item-icon" />}
        placeholder="Email"
        value={userEmail}
      />
    </Form.Item>
    <Form.Item>
      <div className="pull-right">
        <Button type="primary" htmlType="submit" className="login-form-button">
          Resend
        </Button>
      </div>
    </Form.Item>
  </Form>
</>
  const confirmationCode =
  <Form
    name="confirmation"
    initialValues={{
      remember: false,
    }}
    onFinish={confirmSignUp}
    onFinishFailed={onFinishFailed}
    autoComplete="off"
  >
    <Text level={4}>
    Enter the 6-digit confirmation code sent to your email.
    </Text>
    <br />
    <br />
    <Form.Item
      name="email"
      rules={[
        {
          required: true,
          message: '',
        }
      ]}
    >
      <Input
        placeholder={"Email"}
        value={userEmail}
        style={{
          width: 'calc(70%)',
        }}
        prefix={<MailOutlined className="site-form-item-icon" />}
      />
    </Form.Item>
    <Form.Item
      name="confirmation_code"
      rules={[
        {
          required: true,
          message: '',
        }
      ]}
    >
      <Input
        style={{
          width: 'calc(70%)',
        }}
        placeholder="Confirmation Code"
      />
    </Form.Item>
    <Form.Item>
      <div className="pull-right">
        <Button type="primary" htmlType="submit" className="login-form-button">
          Confirm
        </Button>
      </div>
    </Form.Item>
  </Form>

  const signUpForm =
  <Form
    name="signup"
    initialValues={{
      remember: false,
    }}
    onFinish={signUp}
    onFinishFailed={onFinishFailed}
    autoComplete="off"
  >
    <Form.Item
      name="name"
    >
      <Input
        prefix={<UserOutlined className="site-form-item-icon" />}
        placeholder="Name"
      />
    </Form.Item>
    <Form.Item
      name="email"
      rules={[
        {
          required: true,
          message: '',
        },
        {
          validator(_, value) {
            if ((/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/).test(value)) {
              return Promise.resolve();
            }
            return Promise.reject('Please enter a valid email address.');
          },
        }
      ]}
    >
      <Input
        prefix={<MailOutlined className="site-form-item-icon" />}
        placeholder={"Email"}
      />
    </Form.Item>
    <Form.Item
      name="password"
      rules={[
        {
          required: true,
          message: '',
        },
        {
          validator(_, value) {
            if (value.length >= 8) {
              return Promise.resolve();
            }
            return Promise.reject('Passwords must contain atleast 8 characters.');
          },
        }
      ]}
    >
      <Input.Password
        prefix={<LockOutlined className="site-form-item-icon" />}
        placeholder="Password"
      />
    </Form.Item>
    <Form.Item
      name="agreement"
      valuePropName="checked"
      rules={[
        {
          required: true,
          message: '',
        },
        {
          validator(_, value) {
            if (!value) {
              return Promise.reject('Please accept the Terms and Conditions to continue.')
            } else {
              return Promise.resolve();
            }
          },
        }
      ]}
    >
      <Checkbox>
        By signing up, I agree to the <TermsAndConditions/>
      </Checkbox>
    </Form.Item>
    <Form.Item>
      <Button type="link" onClick={finishSignUpWithCode}>
        I already have a code
      </Button>
      <Button type="link" onClick={triggerResendCodeModal}>
        Resend code
      </Button>
      <div className="pull-right">
        <Button type="primary" htmlType="submit" className="login-form-button">
          Sign Up
        </Button>
      </div>
    </Form.Item>
  </Form>

  const content = {"signUpForm": signUpForm, "confirmationCode" : confirmationCode, "logInModal" :logInModal, "resendCodeModal" :resendCodeModal}
  const modalTitles = {"signUpForm": "Sign Up", "confirmationCode": "Account Verification", "logInModal": " Login", "resendCodeModal": "Resend Code"}

  return (
    <>
      <a className='btn btn-custom btn-lg page-scroll'  onClick={showModal}>
        {props.buttonText == null ? "Sign Up" : props.buttonText}
      </a>{' '}
      <Modal
        title={modalTitles[stepNum]}
        visible={isModalVisible}
        visible={isModalVisible}
        onCancel={handleCancel}
        closable={true}
        footer={null}
      >
      {content[stepNum]}
      </Modal>
    </>
  );
};

export default SignUpModal;
